$font-path: "../fonts/";

@font-face {
    font-family: 'Costa Display Wave';
    src: url($font-path + 'CostaDisplay-WaveBold.woff2') format('woff2'),
    url($font-path + 'CostaDisplay-WaveBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
/*
Example;

@font-face {
    font-family: "Example-Font";
    src: url($font-path + 'Example-Font.otf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

body {
    font-family: "Example-Font", "system", sans-serif;
}

.example-font {
    font-family: "Example-Font", "system", sans-serif;
    font-weight: 400;
}
*/
