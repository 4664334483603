@import url("https://use.typekit.net/ofj1gxm.css");

// Tailwind CSS
@import "~tailwindcss/base";
@import "~tailwindcss/components";
@import "~tailwindcss/utilities";
@import './typography';

.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
}

// quill-source-code-button editor
.ql-html-editor > textarea {
	height: 300px !important;
	width: 100% !important;
	background-color: white !important;
	padding: 10px !important;
	color: black !important;
	z-index: 100 !important;
	border: 1px solid black !important;
}

.ql-editor.render-ql-editor {
	padding: 0 !important;
}

.tooltip .tooltiptext {
  visibility: hidden;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}

body {
   //background-image: url("/images/background.png");
    background-color: #6B1831 ;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    min-height: 100vh;
}

.bg-catch-game {
    background-blend-mode: color;
    background-color: rgba(255,255,255,.6);
}
